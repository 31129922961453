<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <form action="" @submit.prevent="onSubmit()">
                    <px-card>
                        <b-row>
                            <b-col md="3" class="font-weight-bold">
                                Alert Contact Type
                                <span class="font-danger">*</span>
                            </b-col>
                            <b-col md="4">
                                <div class="form-group">
                                <b-form-select name="type" size="sm" v-model="input.type" :options="alert_opt" :state="validate_type" 
                                :disabled="edit_alert" required></b-form-select>
                                <b-form-invalid-feedback :state="validate_type">Select the alert contact type</b-form-invalid-feedback>
                                </div>
                            </b-col>
                        </b-row>
                        <hr>
                        <div v-show="input.type != null">
                            <b-row class="mb-2">
                                <b-col md="3" class="font-weight-bold">
                                    Friendly Name
                                    <span class="font-danger">*</span>
                                </b-col>

                                <b-col md="4">
                                    <b-form-input size="sm" type="text" name="name" v-model="input.name" :state="isNameEntered ? !invalid_fname_message : null"  @input="validateName"  maxlength="50" placeholder="Name" required></b-form-input>
                                    <b-form-invalid-feedback v-if="isNameEntered">{{ invalid_fname_message }}</b-form-invalid-feedback>
                                </b-col>
                            </b-row><hr>
                        <div v-if="input.type == 5">
                            <b-row v-if="input.type == 5" class="">
                                <b-col md="3" class="font-weight-bold">
                                    Secret Key
                                    <span class="font-danger">*</span>
                                </b-col>
                                <b-col md="6">
                                    <b-form-input size="sm" type="text" v-model="input.sk_webhook" maxlength="80" placeholder="Secret Key" :state="isKeyEntered ? !invalid_key_message : null" @input="validateKey" required></b-form-input>
                                    <b-form-invalid-feedback v-if="isKeyEntered">{{ invalid_key_message }}</b-form-invalid-feedback>
                                </b-col>
                            </b-row><hr>
                        </div>
                            <b-row v-if="input.type != 4" class="">
                                <b-col md="3" class="font-weight-bold">
                                    <span v-if="input.type">{{ related_id_lable[input.type].text }}</span>
                                    <span class="font-danger">*</span>
                                </b-col>
                                <b-col md="6">
                                    <b-form-input 
                                    size="sm" 
                                    type="text" 
                                    v-model="input.alertToUrl" 
                                    :placeholder="inputPlaceholder" 
                                    maxlength="150" 
                                    :state="isUrlEntered ? !invalid_url_message : null"
                                    @input="validateAlertUrl"
                                    required
                                    >
                                    </b-form-input>

                                    <b-form-invalid-feedback v-if="isUrlEntered">{{ invalid_url_message }}</b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <hr>
                            <div v-if="input.type == 10">
                                <b-row>
                                    <b-col md="3" class="font-weight-bold">
                                        Key
                                        <span class="font-danger">*</span>
                                    </b-col>
                                    <b-col md="6">
                                        <b-form-input size="sm" type="text" v-model="input.key" maxlength="80" placeholder="Key" :state="isZulipKeyEntered ? !invalid_zulip_key_message : null" @input="validateZulipKey" required></b-form-input>
                                        <b-form-invalid-feedback v-if="isZulipKeyEntered">{{ invalid_zulip_key_message }}</b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                                <hr>
                                <b-row>
                                    <b-col md="3" class="font-weight-bold">
                                    Site
                                    <span class="font-danger">*</span>
                                    </b-col>
                                    <b-col md="6">
                                        <b-form-input size="sm" type="text" v-model="input.site" maxlength="80" placeholder="Site" :state="isSiteEntered ? !invalid_site_message : null" @input="validateSite" required></b-form-input>
                                        <b-form-invalid-feedback v-if="isSiteEntered">{{ invalid_site_message }}</b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                                <hr>
                                <b-row>
                                    <b-col md="3" class="font-weight-bold">
                                        Channel
                                        <span class="font-danger">*</span>
                                    </b-col>
                                    <b-col md="6">
                                        <b-form-input size="sm" type="text" v-model="input.channel" maxlength="80" placeholder="Channel" :state="isChannelEntered ? !invalid_channel_message : null" @input="validateChannel" required></b-form-input>
                                        <b-form-invalid-feedback v-if="isChannelEntered">{{ invalid_channel_message }}</b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                                <hr>
                                <b-row>
                                    <b-col md="3" class="font-weight-bold">
                                        Topic
                                        <span class="font-danger">*</span>
                                    </b-col>
                                    <b-col md="6">
                                        <b-form-input size="sm" type="text" v-model="input.topic" maxlength="80" placeholder="Topic" :state="isTopicEntered ? !invalid_topic_message : null" @input="validateTopic" required></b-form-input>
                                        <b-form-invalid-feedback v-if="isTopicEntered">{{ invalid_topic_message }}</b-form-invalid-feedback>
                                    </b-col>
                                </b-row>
                                <hr>
                            </div>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 1">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ul>
                                        <li>Please enter Email Id above.</li>
                                    </ul>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 2">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ul>
                                        <li>Please enter Skype Id above.</li>
                                        <li>Example: <span class="text-info">live:{skypeid}</span></li>
                                    </ul>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 3">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ol>
                                        <li>Please get telegram username from telegram profile.</li>
                                        <li>Your telegram username will look like <span class="text-info">@demousername</span>, but enter it without the <span class="text-danger">@</span>.</li>
                                        <li>Validation - You can use a-z, 0-9 and underscores. Minimum length is 5 characters and maximum length 32 characters.</li>
                                    </ol>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 4">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ol>
                                        <li>Please enter alert name only.</li>
                                    </ol>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 5">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ol>
                                        <li v-if="input.type">Please enter Webhook url in {{ related_id_lable[input.type].text }}.</li>
                                    </ol>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 6 || input.type == 7">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ol>
                                        <li v-if="input.type">Please enter Mobile number with country code in {{ related_id_lable[input.type].text }}.</li>
                                        <li>for example : <span class="text-info">+19999999999</span></li>
                                    </ol>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 8">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ol>
                                        <li v-if="input.type">Please enter Slack Email in {{ related_id_lable[input.type].text }}.</li>
                                    </ol>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 9">
                                <b-col>
                                    <span class="text-danger">Note:-</span>
                                    <ol>
                                        <li v-if="input.type">Please enter Zappier webhook url in {{ related_id_lable[input.type].text }}.</li>
                                    </ol>
                                </b-col>
                            </b-row>
                            <b-row class="col-md-12 mt-3" v-show="input.type == 10">
                                <b-col>
                                    <span class="text-danger">Note:</span>
                                    <ol>
                                        <li>Go to <b>Settings</b> > <b>Organization/Personal settings</b> > <b>Bots</b>.</li>
                                        <li>Click <b>Add new bot</b>.</li>
                                        <li>Fill in the details and click <b>Add</b>.</li>
                                    </ol>
                                </b-col>
                            </b-row>
                            <hr>
                            <b-row class="mt-3 text-center">
                                <b-col>
                                    <b-button type="submit" variant="primary" :disabled="disable_submit">{{edit_alert?'Edit Alert':'Add New Alert'}}</b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </px-card>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
import AlertController from "../../services/alertController";
export default{
    name : "AddAlerts",
    props:{add_alert_modal:Boolean,edit_alert:Boolean,alert_data:Object},
    data(){
        return {
            disable_submit:false,
            input:{type:null,name:'',alertToUrl:''},
            alert_opt:[
              { value: null, text:'Please Select' },
              { value: 1, text:'Email', disabled:true },
              { value: 2, text:'Skype', disabled:true  },
              { value: 3, text:'Telegram', disabled:true  },
              { value: 4, text:'Web push', disabled:true  },
              { value: 5, text:'Webhook', disabled:true  },
              { value: 6, text:'Message', disabled:true  },
              { value: 7, text:'Call', disabled:true  },
              { value: 8, text:'Slack', disabled:true  },
              { value: 9, text:'Zappier webhook', disabled:true  },
              { value: 10, text: 'Zulip', disabled: true }
            ],
            related_id_lable:[
              { value: null, text:'Please Select' },
              { value: '1', text:'Email Id ' },
              { value: '2', text:'Skype Id ' },
              { value: '3', text:'Telegram Id ' },
              { value: '4', text:'Web Push Url ' },
              { value: '5', text:'Webhook Url ' },
              { value: '6', text:'Contact Number ' },
              { value: '7', text:'Contact Number ' },
              { value: '8', text:'Slack User email ' },
              { value: '9', text:'Zappier Webhook Url' },
              { value: '10', text: 'Zulip bot email' }
            ],
            plan_details:[],
            payload:{},
            invalid_fname_message: 'The name field is required',
            isNameEntered: false,
            isKeyEntered: false,
            invalid_key_message: 'The key field is required',
            isUrlEntered: false,
            invalid_url_message: 'The field is required',
            isZulipKeyEntered: false,
            invalid_zulip_key_message: 'The field is required',
            isSiteEntered: false,
            invalid_site_message: 'The site field is required',
            isChannelEntered: false,
            invalid_channel_message: 'The channel field is required',
            isTopicEntered: false,
            invalid_topic_message: 'The topic field is required',
        }
    },
    computed:{
        validate_type(){
            if(this.input.type == null){return false;}
            else{return true;}
        },
        inputPlaceholder(){
            if(this.input.type == 1){return 'Email Id'}
            else if(this.input.type == 2){return 'Skype Id'}
            else if(this.input.type == 3){return 'Telegram Id'}
            else if(this.input.type == 4){return 'Web Push Url'}
            else if(this.input.type == 5){return 'Webhook Url'}
            else if(this.input.type == 6 || this.input.type == 7){return 'Contact Number'}
            else if(this.input.type == 8){return 'Slack User email'}
            else if(this.input.type == 9){return 'Zappier Webhook Url'}
            else if(this.input.type == 10){return 'Zulip bot email'}
        }
    },
    created(){
        this.plan_details = this.$root.$data.plan_details
        for(var i=0; i< this.alert_opt.length; i++ ){
            this.plan_details.alertsTypes.map((mt) => { 
                if(this.alert_opt[i].value == mt || this.alert_opt[i].value == 10){
                    this.alert_opt[i].disabled = false
                }
             })
        }
    },
    mounted(){
        this.$root.$data.alert_id = ''
        if(this.edit_alert){
            this.invalid_fname_message = '';
            this.isNameEntered = true;
            this.isKeyEntered = true;
            this.invalid_key_message = '';
            this.isUrlEntered = true;
            this.invalid_url_message = '';
            this.isZulipKeyEntered = true;
            this.invalid_zulip_key_message = '';
            this.isSiteEntered = true;
            this.invalid_site_message = '';
            this.isChannelEntered = true;
            this.invalid_channel_message = '';
            this.isTopicEntered = true;
            this.invalid_topic_message = '';

            this.input = this.alert_data
        }
    },
    methods:{
       async onSubmit(){
        this.disable_submit = true
        this.payload = {"name": this.input.name,"type": this.input.type,"alertToUrl": this.input.alertToUrl}
        switch(this.input.type){
            case 1: 
                if(this.validateEmail(this.input.alertToUrl)){
                    this.payload = {"name": this.input.name,"type": this.input.type,"alertToUrl": this.input.alertToUrl}
                }
                else{
                    this.payload = {}
                    this.$toasted.show("Invalid Email", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                }
                break;
            case 3: 
                    let validateTelegram = this.isValidTelegramId(this.input.alertToUrl)
                    if(!validateTelegram.valid){
                    this.$toasted.show(validateTelegram.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                    this.disable_submit = false
                        return;
                    }
                break;
            case 4: this.payload = {"name": this.input.name,"type": this.input.type}
                break;
            case 5: this.payload.sk_webhook = this.input.sk_webhook
                break;
            case 8: 
                if(!this.validateEmail(this.input.alertToUrl)){
                    this.$toasted.show("Invalid Email", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                    this.disable_submit = false
                    return;
                }
                break;
            case 10:
                if(this.validateEmail(this.input.alertToUrl)){
                    this.payload = {"name": this.input.name,"type": this.input.type,"alertToUrl": this.input.alertToUrl, "key": this.input.key, "site": this.input.site, "channel": this.input.channel, "topic": this.input.topic }
                } else {
                    this.$toasted.show("Invalid Email", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                    this.disable_submit = false
                    return;
                }
        }
        if(Object.keys(this.payload).length !== 0){
                var response
                if(this.edit_alert){
                     var p = { "acid": this.input._id, "changePayload":this.payload }
                     response= await AlertController.editAlert(p)
                }
                else{
                    response= await AlertController.createAlert(this.payload)
                    if(response.data && response.data.aid){
                      this.$root.$data.alert_id = response.data.aid}
                }
                if(response.result){
                    this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
                    this.$emit('alertmodal')
                }
                else{
                    this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                }
            }
            this.disable_submit = false
        },
        validateEmail(email) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(email);
          },
        isValidTelegramId(input) {
            const regexPattern = /^[a-zA-Z0-9_]{5,32}$/;
            if (!input) {
                return { valid: false, message: "Telegram ID is required." };
            }
            if (input.length < 5 || input.length > 32) {
                return { valid: false, message: "Telegram ID must be between 5 and 32 characters." };
            }
            if (!regexPattern.test(input)) {
                return { valid: false, message: "Invalid Telegram ID. It can only contain letters, digits, and underscores." };
            }
             return { valid: true, message: "Valid Telegram ID." };
        },
        validateName(name){
            this.isNameEntered = true;
            name = name ? name : this.input.name

            let regexp = /^(?!\s*$)[a-zA-Z0-9 ._-]+$/;
            if (regexp.test(name)) {
                this.invalid_fname_message = false;
                return true;
            } 
            else{ 
                this.invalid_fname_message = 'Please enter valid friendly name';
                return false; 
            }
        },
        validateKey() {
            this.isKeyEntered = true;
            if(this.input.sk_webhook){
                this.invalid_key_message = false;
                return true;
            }
            else{
                this.invalid_key_message = 'Please enter valid secret key';
                return false;
            }
        },
        validateZulipKey() {
            this.isZulipKeyEntered = true;
            if(this.input.key){
                this.invalid_zulip_key_message = false;
                return true;
            }  else {
                this.invalid_zulip_key_message = 'Please enter valid key';
                return false;
            }
        },
        validateSite() {
            this.isSiteEntered = true;
            let siteRegex = /^(https?:\/\/)[^\s$.?#].[^\s]*$/;
            if(siteRegex.test(this.input.site)){
                this.invalid_site_message = false;
                return true;
            } else {
                this.invalid_site_message = 'Please enter valid site url';
                return false;
            }
        },
        validateChannel() {
            this.isChannelEntered = true;
            if(this.input.channel){
                this.invalid_channel_message = false;
                return true;
            } else {
                this.invalid_channel_message = 'Please enter valid channel';
                return false;
            }
        },
        validateTopic() {
            this.isTopicEntered = true;
            if(this.input.topic){
                this.invalid_topic_message = false;
                return true;
            } else {
                this.invalid_topic_message = 'Please enter valid topic';
                return false;
            }
        },
        validateAlertUrl() {
            this.isUrlEntered = true;
            let alertUrl = this.input.alertToUrl;
            let type = this.input.type;

            let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            let skypeIdRegex = /^(live:)?([a-zA-Z][a-zA-Z0-9._-]{5,31}|\.cid\.[a-f0-9]{16})$/;
            let telegramIdRegex = /^[a-zA-Z0-9_]{5,32}$/;
            let phoneRegex = /^\+(\d{1,3})[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
            let urlRegex = /^(https?:\/\/)[^\s$.?#].[^\s]*$/;

            if (type === 1 || type === 8 || type === 10) {
                if (!emailRegex.test(alertUrl)) {
                    this.invalid_url_message = 'Please enter a valid email';
                    return false;
                }
            } 
            else if (type === 2) {
                if (!skypeIdRegex.test(alertUrl)) {
                    this.invalid_url_message = 'Please enter a valid Skype ID';
                    return false;
                }
            } 
            else if (type === 3) { 
                if (!telegramIdRegex.test(alertUrl)) {
                    this.invalid_url_message = 'Please enter a valid Telegram username';
                    return false;
                }
            } 
            else if (type === 5 || type === 9) {
                if (!urlRegex.test(alertUrl)) {
                    this.invalid_url_message = 'Please enter a valid URL';
                    return false;
                }
            } 
            else if (type === 6 || type === 7) { 
                // let digitCount = alertUrl.replace(/\D/g, '').length;

                // if (digitCount >= 11 && digitCount <= 11) {
                //     this.invalid_url_message = 'Please enter valid phone number length';
                //     return false;
                // }
                let newRegex =  /^\+?\d{10,13}$/;
                if(!newRegex.test(alertUrl)) {
                    this.invalid_url_message = 'Please enter a valid phone number';
                    return false;
                }
            } 
            else {
                this.invalid_url_message = 'Invalid alert type';
                return false;
            }

            this.invalid_url_message = '';
            return true;
        },
    }
}
</script>