<template>
  <div>
    <Breadcrumbs main="" title="Alert Contacts" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 mt-1">
            <div slot="with-padding">
              <b-row class="sectionShowDesktop">
                <b-col class="input-group cus-search-input">
                  <div class="form-group input-group-square">
                    <b-input-group >
                      <b-input-group-prepend >
                        <span class="custom-font-22 input-group-text search-bg border-right-0"><i class="icofont icofont-search-alt-2"></i></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class="custom-font-22 border-left-0"
                        v-model="filter"
                        placeholder="Type to Search"
                        type="search"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </b-col>
                <b-col class="col-md-8 col-12 d-flex justify-content-end">
                  <b-form-group
                      label-cols="6"
                      label="Per page"
                      class="text-right custom-font-20 text-secondary border border-dark pr-0 pl-1 mr-2 b-r-8 d-inline-block"
                    >
                    <b-form-select
                      class="custom-font-22 text-dark border-0 b-r-8"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="getAlertContacts"
                    ></b-form-select>
                    </b-form-group>
                  <div>
                    <button class="btn mr-2 custom-font-24" :class="btn_class" @click="add_alerts_modal = true" >+ Add New Alert</button>
                  </div>
                  <add-monitor-button class="hideMonitorBtn"></add-monitor-button>
                </b-col>
              </b-row>
              <b-row class="sectionShowMobile">
                <b-col class="cus-search-input">
                  <div class="input-group form-group input-group-square">
                    <b-input-group >
                      <b-input-group-prepend >
                        <span class="custom-font-22 input-group-text search-bg border-right-0"><i class="icofont icofont-search-alt-2"></i></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class="custom-font-22 border-left-0"
                        v-model="filter"
                        placeholder="Type to Search"
                        type="search"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </b-col>
                <b-col class="mt-3">
                 <b-row class="d-flex justify-content-between">
                  <div class="col-6">
                    <b-form-group
                      label-cols="6"
                      label="Per page"
                      class="col-md-8 text-right custom-font-20 text-secondary border border-dark pr-0 pl-0 b-r-8 d-inline-block"
                    >
                    <b-form-select
                      class="custom-font-22 text-dark border-0 b-r-8"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="getAlertContacts"
                    ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="pr-2">
                    <button class="btn custom-font-24 mr-2" :class="btn_class" @click="add_alerts_modal = true" >+ Add New Alert</button>
                  </div>
                 </b-row>
                </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                <!-- <h6 class="sub-title mb-0 text-center">Loading...</h6> -->
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <div v-else class="table-responsive datatable-vue text-left custom-font-18 alert-table" style="overflow: hidden;">
                <b-table
                  show-empty
                  stacked="md"
                  :items="items"
                  :filter="filter"
                  :fields="tablefields"
                  :per-page="perPage"
                  @filtered="onFiltered"
                >
                <!-- <template #table-caption>AlertContacts List.</template> -->

                <!-- <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template> -->

                <template #cell(name)="data">
                  <span class="">{{ data.item.name }}</span>
                </template>
                <template #cell(type)="data">
                  <span class="">{{ alert_opt[data.item.type].text }}</span>
                </template>
                <template #cell(alertToUrl)="data">
                  <div class="res_text">{{ data.item.alertToUrl }}</div>
                </template>
                <template #cell(status)="data">
                    <div class="media-body switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=data.item.status @change="statusUpdate(data.item)"><span class="switch-state" :class="data.item.status?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>                 
                </template>
                <template #cell(isVerified)="data">
                  <button v-if="data.item.isVerified == true" class="btn custom-font-18 pr-3 pl-3 pt-1 pb-1 btn-dark" @click="sendTestMessageAlert(data.item)">
                    <i class="icon-bell alert-icon"></i>
                    Test Notification
                  </button>     
                  <span v-if="!data.item.isVerified && data.item.type != 5" class="text-danger" @click="openOtpModal(data.item)" style="cursor:pointer;text-decoration: underline;">Click here to Verify</span>
                </template>
                 <template #cell(isFallback)="data">
                    <div class="media-body switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=data.item.isFallback @change="fallbackUpdate(data.item)"><span class="switch-state" :class="data.item.isFallback?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>                 
                </template>
                <template #cell(action)="data">
               
                 
                  <feather class="text-danger" type="trash-2" title="Delete" @click="deleteAlert(data.item._id)" style="cursor: pointer;"></feather>
                  <feather class="text-dark ml-2" type="edit" title="Edit" @click="editAlert(data.item)" style="cursor: pointer;"></feather>         
                  
                </template>
                </b-table>
              </div>
              <b-col md="12" class="mt-3 text-right p-0">
                <div class="pagination">
                  <a v-show="currentPage != 1" @click="getAlertContacts('prev')">&laquo; Back</a>
                  <a class="active">{{currentPage}}</a>
                  <a v-show="items.length >= perPage" @click="getAlertContacts('next')">Next &raquo;</a>
                </div>
              </b-col>
            </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-monitor" size="lg" @hidden="closeModal()" title="Alert Contact" class="theme-modal" v-model="add_alerts_modal" no-close-on-backdrop hide-footer>
        <add-contact :add_alerts_modal="add_alerts_modal" @alertmodal="closeModal" :edit_alert="edit_alert" :alert_data="alert_data"></add-contact>
        <!-- <template #modal-footer>
        <b-button @click="closeModal()" color="danger">Close</b-button>
        </template> -->
    </b-modal>

    <b-modal id="verify-otp" size="" @hidden="getAlertContacts()" title="Verification" class="theme-modal" v-model="verify_otp_modal" no-close-on-backdrop hide-footer> 
        <div class="col-md-12 mt-3" v-if="otp_data.type == 1">
          <b-row>
            <p class="text-danger">Follow below steps to verify Email.</p>
            <ol class="col-md-12 font-weight-bold">
              <li>Click on below Send Verification Code Button.</li>
              <li>You will receive code on registered mail id.</li>
              <li>Enter code and get verified</li>
            </ol>
          </b-row>
        </div>
        <div class="col-md-12 mt-3" v-if="otp_data.type == 2">
          <b-row>
            <p class="text-danger">Follow the steps mentioned below for Skype verification.</p>
            <ol class="col-md-12 font-weight-bold">
              <li>Send a request to our skype id <span class="text-info">{{skypeid}}</span> and wait for a while.</li>
              <li>Click on Send Verification Code button.</li>
              <li>You will receive a verification code on your registered Skype id.</li>
              <li>Enter the code and your Skype account will get verified..</li>
            </ol>
          </b-row>
        </div>
        <div class="col-md-12 mt-3" v-if="otp_data.type == 3">
          <b-row>
            <p class="text-danger">Follow below steps to verify Telegram.</p>
            <ol class="col-md-12 font-weight-bold">
              <li>Find Bot in telegram <a :href="'https://t.me/' + telegramBot" class="text-info" target="_blank">
   {{telegramBot}}
</a>.</li>
              <li>Enter '/start'.</li>
              <li>After successfully subscribed on telegram click on given below Send Verification Code Button.</li>
              <li>You will receive otp code on telegram. Enter varification code and Submit to verified.</li>
              <li>If still did't receive verification code then please edit your Alert and verify username in related ID field.</li>
            </ol>
          </b-row>
        </div>
        <div class="col-md-12 mt-3" v-if="otp_data.type == 4">
          <b-row>
            <b-col class="text-center">
            <p class="text-danger">Copy below url and Open in device browser where you want notification.</p>
            <code>{{ webpushurl+'acid='+otp_data._id }}</code>
            </b-col>
          </b-row>
          <b-row class="mt-3">
          <b-col class="text-center">
            <b-button variant="success" size="sm" @click="copyUrl(webpushurl+'acid='+otp_data._id)">Copy Url</b-button>
          </b-col>
          </b-row>
        </div>
        <div class="col-md-12 mt-3" v-if="otp_data.type == 6 || otp_data.type == 7">
          <b-row>
            <p class="text-danger">Follow below steps to verify.</p>
            <ol class="col-md-12 font-weight-bold">
              <li>Click on below Send Verification Code Button.</li>
              <li>You will receive code on your mobile number.</li>
              <li>Enter code and get verified</li>
            </ol>
          </b-row>
        </div>
        <div class="col-md-12 mt-3" v-if="otp_data.type == 8">
          <b-row>
            <b-col class="text-center">
              <p class="text-danger">Click On Add to Slack button to verify.</p>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="text-center">
            <a :href="slacVerifyurl"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
            </b-col>
          </b-row>
        </div>
        <div class="col-md-12 mt-3" v-if="otp_data.type == 9">
          <b-row>
            <b-col class="">
              <p class="text-danger">Follow below steps to verify.</p>
              <ol class="col-md-12 font-weight-bold">
                <li>Click on below Send Verification Code Button.</li>
                <li>You will receive verification code on Zappier webhook.</li>
                <li>Enter code and get verified</li>
              </ol>
            </b-col>
          </b-row>
        </div>
        <div class="col-md-12 mt-3" v-if="otp_data.type == 10">
          <b-row>
            <b-col class="">
              <p class="text-danger">Follow below steps to verify.</p>
              <ol class="col-md-12 font-weight-bold">
                <li>Click on below Send Verification Code Button.</li>
                <li>You will receive verification code on Zulip Bot.</li>
                <li>Enter code and get verified.</li>
              </ol>
            </b-col>
          </b-row>
        </div>
        <div v-if="verify_pin == false && otp_data.type != 4 && otp_data.type != 8">
          <b-row class="mt-3">
          <b-col class="text-center">
            <b-button variant="success" size="sm" @click="sendOTP()">Send verification Code</b-button>
          </b-col>
          </b-row>
        </div>
        <div v-if="verify_pin == true">
          <b-row>
            <b-col class="text-center">
              <b-input v-model="input.otp" placeholder="Enter verification code" :state="isOtpEntered ? !invalid_otp_message : null" @input="validateOtp" required></b-input>
              <b-form-invalid-feedback v-if="isOtpEntered">{{ invalid_otp_message }}</b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="mt-3" v-if="isOtpEntered && !invalid_otp_message">
            <b-col class="text-center">
              <b-button @click="verifyOtp()" variant="success">Verify</b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="text-center">
              <span>didn't receive code <span class="link_txt" @click="sendOTP()">click here to resend.</span> </span>
            </b-col>
          </b-row>
        </div>
        <div class="col-md-12" v-if="otploading">
            <h6 class="sub-title mb-0 text-center">Loading...</h6>
              <div class="loader-box">
                <div class="loader-30"></div>
              </div>
          </div> 
        <template #modal-footer>
        <b-button @click="closeVerifyModal()" color="danger">Close</b-button>
        </template>
    </b-modal>
 </div>
</template>


<script>
import AlertController from "../../services/alertController";
import AddContact from "./AddAlerts.vue";
import Conf from '../../services/config';
import AddMonitorButton from ".././components/addMonitorButton.vue";
let socket;
export default {
    name: 'AlertContact',
    components:{AddContact, AddMonitorButton},
    data(){
        return{
            input:{},
            verify_pin:false,
            otp_data:[],
            verify_otp_modal:false,
            alert_data:{},
            otploading:false,
            add_alerts_modal:false,
            edit_alert: false,
            items:[],
            totalRows:0,
            filter:null,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 50],
            tablefields: [
                          { key: "name", label: "Name", sortable: true },
                          { key: "alertToUrl", label: "Alert To Url", sortable: false },
                          { key: "type", label: "Type", sortable: false },
                          { key: "status", label: "Status", sortable: false },
                          { key: "isVerified", label: "Verify Status", sortable: false },
                          { key: "isFallback", label: "Use As Fallback", sortable: false },
                          { key: "action", label: "Action", sortable: false },
                        ],
            loading: false,
            alert_opt:[
              { value: null, text:'Please Select' },
              { value: '1', text:'Email' },
              { value: '2', text:'Skype' },
              { value: '3', text:'Telegram' },
              { value: '4', text:'Web push' },
              { value: '5', text:'Webhook' },
              { value: '6', text:'Message' },
              { value: '7', text:'Call' },
              { value: '8', text:'Slack' },
              { value: '9', text:'Zappier webhook' },
              { value: '10', text: 'Zulip' },
            ],
            telegramBot:'',
            webpushurl:'',
            slacVerifyurl:'',
            skypeid:'',
            userinfo:'',
            btn_class:'',
            isOtpEntered: false,
            invalid_otp_message: 'Please enter verification code',
        }
    },
    mounted(){
      this.$root.$data.alert_id = ''
      this.getAlertContacts()
      this.telegramBot = Conf.telegramBot
      this.webpushurl = Conf.webPushUrl
      this.slacVerifyurl = Conf.slacVerifyurl
      this.skypeid = Conf.skypebot

      let pathName = this.$route.name
      if (pathName === 'Dashboard' || pathName === 'Incidents' || pathName === 'SubUser' || pathName === 'Profile') {
          this.btn_class = 'custom-btn-dark';
      } else {
          this.btn_class = 'add-monitor-btn-green';
      }
    },
    methods:{
        async fallbackUpdate(data) {
        let s = true
        if(data.isFallback == true){
            s = false
        }

        if (data.isFallback == false) {
          data.isFallback = true;
        } else {
          data.isFallback = false;
        }

        var payload = {"acid": data._id, "changePayload": {"isFallback":s}}
        let response = await AlertController.editAlert(payload)
        if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            // this.getAlertContacts()
        }
        else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
            this.getAlertContacts()
          }
        },
        async getAlertContacts(txt){
          this.loading = true;
            this.items = []
            if(txt == 'next'){this.currentPage ++}
            if(txt == 'prev'){
              if(this.currentPage != 1){
              this.currentPage --
              }
            }
            let paginationQuery = '?currentPage='+this.currentPage+'&pageSize='+this.perPage
            let response = await AlertController.getAll(paginationQuery)
            if (response.result) {
                this.items = response.data;
                this.totalRows = this.items.length;
            }
            if(this.$root.$data.alert_id != ''){
              let data = this.items.filter((t)=> t._id===this.$root.$data.alert_id)
              if(data[0].type != 5){
              this.openOtpModal(data[0])
            }
              this.$root.$data.alert_id = ''
            }
            this.loading = false;

            try {
              if (socket.readyState == WebSocket.OPEN) {
                socket.close();
              }
            } catch (e) {
              console.log("ERROR: error in check status" + e);
            }
            socket = new WebSocket(
              process.env.VUE_APP_SOCKETURL
            );
            socket.onopen = function (e) {
              console.log("[open] Connection established");
              console.info("Sending to server");
              this.userinfo = JSON.parse(window.localStorage.getItem('userinfo'))
              let data = {
                userid: this.userinfo.userid
              };
              socket.send(JSON.stringify(data));
            };
            socket.onerror = function (error) {
              console.error("error:", error);
              throw new Error("Unable to Open Socket");
            };
            socket.onclose = function (e) {
              console.log("[close] connection closing");
            };
            socket.onmessage = async function (event) {
              try {
                this.getAlertContacts()
              } catch (e) {
                console.log(e);
              }
            }.bind(this);
        },
        async sendTestMessageAlert(alertDetail) {
         const alertId = alertDetail["_id"]
         let response = await AlertController.sendTestMessage({"acid": alertId});
         if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
          }else{
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
        }
          return 
        },
        async deleteAlert(id){
          if(confirm("Are you sure want to delete?")){
          this.loading = true;
          let response = await AlertController.deleteAlerts({"acid": id});
          if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            this.getAlertContacts()
          }
          else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
            this.getAlertContacts()
          }
        }
        },
        editAlert(data){
          this.add_alers_modal = false
          this.add_alerts_modal = true
          this.alert_data = data;
          this.edit_alert = true;
        },
        closeModal(){
          this.add_alerts_modal = false
          this.edit_alert = false;
          this.getAlertContacts();
        },
        closeVerifyModal(){
          this.verify_otp_modal = false
          this.getAlertContacts();
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async statusUpdate(data){
        // this.loading = true;
        var s = true
        if(data.status == true){
            s = false
        }
        if (data.status == false) {
          data.status = true;
        } else {
          data.status = false;
        }
        var payload = {"acid": data._id, "changePayload": {"status":s}}
        let response = await AlertController.editAlert(payload)
        if(response.result){
          let toastMessage = data.status ? "Alert activated successfully" : "Alert deactivated successfully";
          this.$toasted.show(toastMessage || response.message, { 
              theme: 'outline', 
              position: "bottom-center", 
              type: 'success', 
              duration: 2000 
          });
            // this.getAlertContacts()
        }
        else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
            this.getAlertContacts()
          }
      },
      openOtpModal(data){
        this.otp_data = []
        this.otp_data = data
        this.verify_otp_modal = true
        this.verify_pin = false
      },
      async sendOTP(){
        this.otploading = true
          var payload = {"contactId": this.otp_data._id}
          let response = await AlertController.sendOtp(payload)
          if(response.result){
            this.$toasted.show('Verification code has been sent to your email', {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            this.verify_pin = true
          }
          else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
          }
        this.otploading = false
       },
       async verifyOtp(){
        this.otploading = true
        let payload = {"contactId":this.otp_data._id ,"otp": this.input.otp}
        let response = await AlertController.verifyOtp(payload)
        if(response.result){
          this.$toasted.show('Successfully verified', {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
          this.input = {}
          this.verify_pin = false
          this.verify_otp_modal = false
          this.getAlertContacts()
        }
        else{
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
        }
        this.otploading = false
       },
       copyUrl(txt){
          const el = document.createElement('textarea');
          el.addEventListener('focusin', e => e.stopPropagation());
          el.value = txt;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          this.$toasted.show('URL copied to clipboard.', {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
        },
      validateOtp() {
        this.isOtpEntered = true;
        if (!this.input.otp || this.input.otp.length == '') {
          this.invalid_otp_message = 'Please enter verification code';
        } else {
          this.invalid_otp_message = '';
        }
      }
    }
}

</script>

<style scoped>
.sectionShowMobile {
 display: none !important;
}

.alert-icon {
  display: inline-block;
  animation: shake 5s ease-in-out infinite;
}

@keyframes shake {
  0%, 90% { transform: rotate(0); }
  92% { transform: rotate(-15deg); }
  94% { transform: rotate(15deg); }
  96% { transform: rotate(-10deg); }
  98% { transform: rotate(10deg); }
  100% { transform: rotate(0); }
}

@media screen and (max-width: 768px) {
  .sectionShowMobile {
    display: block !important;
  }

  .sectionShowDesktop {
    display: none !important;
  }

}
</style>

<style>
.bg-danger{
  background-color: #E70000 !important;
}

</style>